<template>
  <div ref="body">
    <custom-header active="" :subActive="current"></custom-header>
    
    <div class="content-container">
      
      <div class="left-bar ">
        <div class="serch-bar flex">
          <div class="serch-box flex align-center">
            <van-icon name="search" />
            <input type="search" v-model="keyword" placeholder="请输入嘉宾名称" @keyup.enter="onSearch">
          </div>
          <button class="search-btn" @click="onSearch">搜 索</button>
        </div>
        <div class="title text-bold">论坛</div>
        <div class="main-menu">
          <div class="row">
            <template v-for="(item, idx) in categorys">
              <div :key="idx" :class="['theme', categoryId == item.categoryId ? 'active' : '']" @click="partChange(item, idx)"><h1>{{item.categoryName}}</h1></div>
            </template>
          </div>
        </div>
      </div>
      
      <div class="guest-content">
        
        <div class="letters-bar flex">
          <span class="letter all" :class="letter === '' ? 'cur': ''" @click="letterChange('')">全部</span>
          <template v-for="item in letters">
            <span :key="item" class="letter" :class="letter === item ? 'cur': ''" @click="letterChange(item)">{{item}}</span>
          </template>
        </div>
        
        <div class="news-list">
          <div class="flex flex-wrap">
            
            <div class="news-item" v-for="(item, idx) in guests" :key="idx">
              <div class="cover">
                <img :src="item.img" :alt="item.guestName" class="block fill">
              </div>
              <div class="content">
                <div class="name text-bold">{{item.guestName}}</div>
                <div class="intro">{{item.details}}</div>
              </div>
            </div>
            
          </div>
          
          <div class="pagination-box flex justify-center">
            <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
          </div>
          
        </div>
        
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getForumGuest, getCommonCategory } from '@/api/index.js'

export default {
  // name: '论坛嘉宾',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'vip',
      pageNo: 1,
      pageSize: 12,
      total: 50,
      pages: 5,
      keyword: '',
      letter: '',
      categoryId: '',
      categorys: [],
      letters: [], // 26个字母
      guests: [],
    }
  },
  created() {
    if (this.$route.query.categoryId) {
      this.categoryId = Number(this.$route.query.categoryId)
    }
    
    this.getLetters();
    this.getCategorys();
    this.getVips();
  },
  methods: {
    getLetters() {
      let letters = [];
      for(var i = 0; i < 26; i++){
        letters.push(String.fromCharCode(65+i)); //输出A-Z 26个大写字母
        // letters.push(String.fromCharCode(97+i)); //输出A-Z 26个小写字母
      }
      this.letters = letters;
    },
    onSearch() {
      this.letter = '';
      this.categoryId = '';
      this.pageNo = 1;
      this.getVips();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getVips();
    },
    letterChange(val) {
      this.letter = val;
      this.pageNo = 1;
      this.getVips();
    },
    partChange(row) {
      if (row.categoryId == this.categoryId) return;
      this.categoryId = row ? row.categoryId : '';
      
      this.pageNo = 1;
      this.getVips();
    },
    getCategorys() {
      getCommonCategory().then(res => {
        let categorys = res.resultData || [];
        this.categorys = categorys;
      })
    },
    getVips() {
      let params = {
        keywords: this.keyword,
        categoryId: this.categoryId,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        first: this.letter,
      };
      
      getForumGuest(params).then(res => {
        let {records = [], total = 0, pages = 1 } = res.resultData;
        this.total = total;
        this.pages = pages;
        this.guests = records
      })
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 0 10px 10px;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 55px;
          height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 13px;
        }
        
        .serch-box {
          width: 290px;
          height: 30px;
          padding: 0 10px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 4px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 13px;
            padding-left: 5px;
          }
        }
    
        .van-icon-search {
          font-size: 16px;
          font-weight: bold;
        }
      }
      
      .title {
        display: none;
      }
      
      .main-menu {
        width: 100%;
        overflow-x: auto;
        
        &::-webkit-scrollbar {
          height: 0;
        }
        
        .row {
          width: auto;
          white-space: nowrap; 
          
          .theme {
            display: inline-block;
            width: 160px;
            cursor: pointer;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #fff;
            line-height: 30px;
            
            &.active {
              border-color: #060211;
            }
            h1 {
              font-weight: normal;
              font-size: 13px;
              font-family: 'SourceHanSansCN-Medium';
            }
            
          }
        }
        
      }
      
    }
    
    .guest-content {
      width: 100%;
      min-height: 280px;
      
      .letters-bar {
        position: fixed;
        top: 135px;
        right: 14px;
        z-index: 10;
        
        width: 22px;
        height: 590px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        
        .letter {
          background-color: #aaa;
          width: 22px;
          height: 16px;
          line-height: 16px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          border-radius: 4px;
          margin-bottom: 5px;
          
          &.all {
            width: 22px;
            height: 32px;
            line-height: 16px;
            padding: 2px 0;
          }
          
          &.cur {
            background-color: #D0043C;
          }
        }
      }
      
      .news-list {
        width: 340px;
        padding: 0 5px;
        
        .news-item {
          width: 155px;
          margin: 0 5px 8px;
          border-radius: 1px;
          
          .cover {
            display: block;
            width: 155px;
            height: 155px;
            border-radius: 4px 4px 0 0;
            overflow: hidden;
          }
          
          .content {
            width: 155px;
            background-color: #D0043C;
            color: #fff;
            border-radius: 0 0 4px 4px;
            padding: 10px;
            box-sizing: border-box;
            
            .name {
              width: 100%;
              box-sizing: border-box;
              margin-bottom: 5px;
              font-size: 14px;
            }
            
            .intro {
              width: 100%;
              height: 16.5px;
              font-size: 12px;
              overflow:hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient:vertical;
            }
          }
          
        }
        
      }
      
      .pagination-box {
        margin-top: 20px;
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 24px;
            border-radius: 4px;
            padding: 0 5px;
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 24px;
          }
        }
      }
    
    }
    
  }
  
}

@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    display: flex;
    width: 280px;
    margin: 10px auto 20px;
    
    .left-bar {
      width: 80px;
      padding: 0 10px 10px;
      box-sizing: border-box;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 16px;
          height: 8px;
          border-radius: 1px;
          padding: 0;
          font-size: 3px;
        }
        
        .serch-box {
          width: 54px;
          height: 8px;
          padding: 2px 3px;
          margin-right: 2px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 1px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 3px;
            padding-left: 2px;
          }
        }
    
        .van-icon-search {
          font-size: 4.8px;
          font-weight: bold;
        }
      }
      
      .title {
        font-size: 6px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }
      
      .theme {
        width: 60px;
        margin: 2px 0;
        cursor: pointer;
        text-align: center;
        border-radius: 1px;
        border: 0.5px solid #fff;
        line-height: 12px;
        
        &.active,
        &:hover {
          border-color: #060211;
        }
        h1 {
          font-weight: normal;
          font-size: 4px;
          font-family: 'SourceHanSansCN-Medium';
        }
        
      }
      
    }
    
    .guest-content {
      width: 190px;
      
      .letters-bar {
        width: 100%;
        margin-bottom: 12px;
        
        .letter {
          background-color: #aaa;
          font-size: 3px;
          width: 6px;
          height: 6px;
          line-height: 6px;
          text-align: center;
          color: #fff;
          margin-right: 1px;
          border-radius: .8px;
          cursor: pointer;
          
          &.all {
            width: 10px;
          }
          
          &.cur {
            background-color: #D0043C;
          }
        }
      }
      
      .news-list {
        
        .news-item {
          width: 43px;
          margin: 0 6px 8px 0;
          border-radius: 1px;
          
          &:nth-child(4n) {
            margin-right: 0;
          }
          
          .cover {
            display: block;
            width: 43px;
            height: 43px;
            border-radius: 1px 1px 0 0;
            overflow: hidden;
          }
          
          .content {
            width: 43px;
            background-color: #D0043C;
            color: #fff;
            border-radius: 0 0 1px 1px;
            padding: 3px;
            box-sizing: border-box;
            
            .name {
              width: 100%;
              box-sizing: border-box;
              margin-bottom: 1px;
              font-size: 3px;
            }
            
            .intro {
              width: 100%;
              height: 16.5px;
              font-size: 3.2px;
              overflow:hidden;
              text-overflow:ellipsis;
              display:-webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient:vertical;
            }
          }
          
        }
        
      }
      
      /deep/ .el-pagination.is-background {
        
        .btn-next .el-icon, 
        .btn-prev .el-icon {
          font-size: 3.6px;
          font-weight: 700;
          height: 8px;
          line-height: 8px;
        }
        
        .btn-next,
        .btn-prev, 
        .el-pager li {
          margin: 0 1px;
          color: #606266;
          min-width: 8px;
          border-radius: 1px;
          padding: 0 1px;
          font-size: 3.6px;
          height: 8px;
          line-height: 8px;
        }
        
        .el-pager li:not(.disabled).active {
          background-color: #409EFF;
          color: #FFF;
        }
        
        .el-pager .more::before {
          line-height: 8px;
        }
      }
    
    }
    
  }
  
}

</style>